* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
}
body {
    opacity: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    min-height: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    position: relative;

    /* FOR MOBILE MENU */
    animation: bugfix infinite 1s;
    -webkit-animation: bugfix infinite 1s;
}

body::after {
    content: '';
    position: absolute;
    width: 100%;
    min-height: 100%;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-image: url(../img/background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    opacity: .2;
    z-index: -1;
}

nav {
    flex-shrink: 0;
    /* position: fixed;
    top: 1.75rem; */
    width: 100%;
    height: auto;
}
nav ul {
    list-style: none;
    width: 70%;
    margin: 1rem auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 5rem;
}
nav ul li {
    text-align: center;
    margin: auto 1rem;
    height: 100%;
}
nav ul li a {
    font-size: 1.25rem;
    text-decoration: none;
    text-transform: uppercase;
    color: #707070;

    display: inline-block;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.brand {
    text-transform: none;
    color: #4d774e;
    font-weight: bold;
}
.brand img {
    height: 4rem;
    margin-top: -15px;
}

.content {
    /* opacity: 0; */
    width: 100%;
    margin: auto;

    flex-grow: 1;
    flex-shrink: 0;
    height: auto;

    display: flex;
    align-items: center;

    text-align: center;
}

.content h2, .content h3, .content ul, .content p {
    height: auto;
}
.content h2 {
    font-size: 2rem;
    color: #4d774e;
}
.content h3 {
    font-size: 1.6rem;
    margin: 1rem auto;
    color: #4d774e;
    color: #444;
    text-align: left;
}
.content p {
    margin: 1rem auto;
    text-align: left;
    font-size: 1.3rem;
    color: #444;
}
.content ul {
    text-align: left;
    width: 95%;
    margin: auto;
    font-size: 1.2rem;
}

.logo {
    height: 200px;
    margin: 0 auto;
}
.logo embed {
    width: 100%;
    height: 100%;
}
/* .logo-man {
    position: relative;
    top: -270px;
    left: -58px;
    width: 120px;
    height: 250px;
    margin: auto;
    z-index: -1;
} */
.logo-man {
    position: relative;
    top: -230px;
    top: -235px;
    left: 95px;
    width: 80px;
    height: 80px;
    margin: auto;
}
.logo-man embed {
    width: 110%;
    height: 110%;
    opacity: .65;
}

.list {
    list-style: none;
    /* list-style-type: '✽ '; */
    color: inherit;
    display: flex;
    width: 900px;
    height: auto;
    margin: 0 auto 0 auto;
    text-align: center;
}
ul li {
    height: 2rem;
    color: inherit;
    margin: .5rem auto;
}
li::before {
    /* content: "_ ";
    color: #02f202; */
}
ul li a {
    font-size: 1.4rem;
    color: #4d774e;
    text-decoration: none;
    font-size: 180%;
}

a {
    color: #444;
    text-decoration: none;
}

a:hover {
    color: #181818;
    cursor: pointer;
}
a.current {
    color: #181818;
}
.more {
    color: #4d774e;
}

button {
    background-color: transparent;
    border: solid 1px #4d774e;
    color: #4d774e;
    padding: 1rem 2rem;
    font-size: 1.3rem;
    border-radius: 3px;
}
button:hover {
    background-color: #5e885f;
    color: white;
    cursor: pointer;
}

footer {
    width: 100%;
    height: 3rem;
    text-align: center;
    color: #484848;
    display: flex;
    justify-content: center;
    align-items: center;
}
footer p {
    height: 1rem;
}

.small {
    font-size: 95%;
}

.gallery {
    /* background: linear-gradient(transparent, #4d774e); */
    /* background: radial-gradient(transparent, #4d774e); */
    background: radial-gradient(#4d774e, transparent);
    border: solid 1px #4d774e;
    box-shadow: 0 0 4px #181818;
    border-radius: 3px;

    padding: 2rem;
    margin: 2rem 2rem;
    
}
.gallery div {
    display: inline-block;
    width: 400px;
    height: 271px;
    margin: 1rem;
}
.gallery img {
    object-fit: cover;
    width: 100%;
    /* border: solid 1px white; */
    
    /* border: solid 1px #4d774e; */
    /* box-shadow: 0 0 4px #181818; */
    /* border-radius: 3px; */
}

.row-3 {
    display: flex;
}

.row-3 div {
    display: inline-block;
    width: 33%;
    text-align: center;
}

form {
    background: linear-gradient(transparent, #4d774e);
    padding: 3rem;
    text-align: left;
    opacity: .75;
    border-radius: 8px;
    margin: 1rem;

    /* border: solid 1px white; */
    border: solid 1px #4d774e;
    box-shadow: 0 0 4px #181818;
    border-radius: 3px;
}
label {
    color: #4d774e;
    font-size: 1.1rem;
    font-weight: bold;
}
form input, form textarea {
    font-size: 1.1rem;
    width: 100%;
    padding: .5rem 1rem;
    margin: 0 auto 1rem auto;
    border: solid 1px #4d774e;
}
form button {
    border: solid 1px white;
    color: white;
}
form button:hover {
    background-color: white;
    color: #4d774e;
    cursor: pointer;
}

.glass {
    background: linear-gradient(transparent, #4d774e);
    /* background: radial-gradient(transparent, #4d774e); */
    /* background: radial-gradient(#4d774e, transparent); */
    border: solid 1px #4d774e;
    box-shadow: 0 0 4px #181818;
    border-radius: 3px;
}

#services .glass {
    margin: 1rem auto;
    padding: 1rem;
}

.blur {
    visibility: hidden;
    /* -webkit-filter: blur(40px);
    -moz-filter: blur(40px);
    -o-filter: blur(40px);
    -ms-filter: blur(40px);
    filter: blur(40px); */
}

.fade-in {
    opacity: 1;
    transition-duration: .85s;
}

.fade-out {
    opacity: 0;
    transition-duration: .85s;
}